// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-ethsurvey-js": () => import("/opt/build/repo/src/pages/work/ethsurvey.js" /* webpackChunkName: "component---src-pages-work-ethsurvey-js" */),
  "component---src-pages-work-mpx-js": () => import("/opt/build/repo/src/pages/work/mpx.js" /* webpackChunkName: "component---src-pages-work-mpx-js" */),
  "component---src-pages-work-subtitlesforyoutube-js": () => import("/opt/build/repo/src/pages/work/subtitlesforyoutube.js" /* webpackChunkName: "component---src-pages-work-subtitlesforyoutube-js" */),
  "component---src-pages-work-tikkle-js": () => import("/opt/build/repo/src/pages/work/tikkle.js" /* webpackChunkName: "component---src-pages-work-tikkle-js" */)
}

